import React, { CSSProperties, useState } from 'react'
import AgoraUIKit, { layout } from 'agora-react-uikit'
import 'agora-react-uikit/dist/index.css'

const urlParams = new URLSearchParams(window.location.search)
const appId = urlParams.get('appId') || '4d94ad0cb2a248bda8701d758cde9913'
const channelGet = urlParams.get('channel') || ''

const App: React.FunctionComponent = () => {
  const [videocall, setVideocall] = useState(true)
  const [isHost, setHost] = useState(true)
  const [isPinned, setPinned] = useState(false)
  /*
  const [appId, setAppId] = useState(urlParams.get('appId') || '')
  const [channelGet, setChannelGet] = useState(urlParams.get('channel') || '')
  */
  const [username, setUsername] = useState(urlParams.get('user') || '')
  


  

  /*
  console.log("Appid=>",appId);
  console.log("channelGet=>",channelGet);
  console.log("username=>",username);
  */



  
  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <h1 style={styles.heading}>Universal Doctor Video</h1>
        {videocall ? (<>
          <div style={styles.nav}>
            <p style={{ fontSize: 20, width: 200 }}>You're {isHost ? 'a host' : 'an audience'}</p>
            <p style={styles.btn} onClick={() => setHost(!isHost)}>Cambia ruolo</p>
            <p style={styles.btn} onClick={() => setPinned(!isPinned)}>Cambia layout</p>
          </div>
          <AgoraUIKit
            rtcProps={{
              appId: appId || '',
              channel: channelGet || '',
              token: null, //add your token if using app in secured mode
              role: isHost ? 'host' : 'audience',
              layout: isPinned ? layout.pin : layout.grid
            }}
            rtmProps={{username: username || 'user', displayUsername: true}}
            callbacks={{
              EndCall: () => setVideocall(false),
            }} /></>
        ) : (
          <div style={styles.nav}>
              <input style={styles.input} placeholder='nickname' type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
            <h3 style={styles.btn} onClick={() => setVideocall(true)}>Start Call</h3>
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  container: { width: '100vw', height: '100vh', display: 'flex', flex: 1, backgroundColor: '#007bff22'},
  heading: { textAlign: 'center' as const, marginBottom: 0 },
  videoContainer: { display: 'flex', flexDirection: 'column', flex: 1 } as CSSProperties,
  nav: { display: 'flex', justifyContent: 'space-around' },
  btn: { backgroundColor: '#007bff', cursor: 'pointer', borderRadius: 5, padding: '4px 8px', color: '#ffffff', fontSize: 20 },
  input: {display: 'flex', height: 24, alignSelf: 'center'} as CSSProperties
}

export default App

